import {TransitionGroup} from "react-transition-group";
import Fade from "./components/utils/Fade/Fade";
import React, { Component } from "react";
import styled from 'styled-components';
import HomePage from "./components/pages/HomePage/HomePage";
// import ScrollToBottom from "./components/utils/ScrollToBottom/ScrollToBottom";
import SelectCard from "./components/pages/SelectCard/SelectCard";
import EnterText from "./components/pages/EnterText/EnterText";
import Details from "./components/pages/Details/Details";
import axios from "axios";
import history from './history';
import qs from "qs";

const Stage = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  position:relative;
  min-height:100vh;
  background: rgb(2,0,36);
background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(73,154,212,1) 100%);
`;

const StageContent = styled.div`
	position:relative;
	z-index:2;
`;


function isNumeric(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

export default class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cards: [],
			lookup: {},
			screen: 2,
			cardid: 1,
			reverse: false,
      submitted: false,
			data: {
				Name: '',
				Text: '',
			},
		}
		setTimeout(() => {
			if(this.state.screen === 1) {
				this.setState({screen:2});
			}
		}, 1)
	}
	route(location) {
		const path = location.pathname.replace("/", "");
		if(path === "select") {
			this.setState({
				screen: 3,
				reverse: false,
			});
			return;
		}
		if(path === "text") {
			this.setState({
				screen: 4,
				reverse: false,
			});
			return;
		}

		if(isNumeric(path)) {
			this.setState({
				screen: 5,
				cardid: path,
				reverse: false,
			});
		} else {
			this.setState({
				screen: 2,
				reverse: false,
			});

		}
	}
	load() {
		const url = this.props.api + "App-Models-Card";
		axios.get(url, {
			headers: {"Accept":"application/json"}
		}).then(res => {
			const data = res.data.items;
			this.setState({
				cards: data,
				lookup: data.reduce((lookup, item) => {
					lookup[item.ID]=item;
					return lookup
				}, {}),
			});
		});
	}
	sendAndReload() {
		const url = this.props.api + "App-Models-Card";

		axios.post(url, qs.stringify(this.state.data), {
			headers: {
				"Accept":"application/json",
				"Content-Type":"application/x-www-form-urlencoded"
			}
		}).then(res => {
			const cardid = res.data.ID;
			this.setState({
				data: {},
				cardid,
				screen: 5,
        submitted: false,
			});
			this.load();
		})

	}
	componentDidMount() {
    this.route(history.location); // Initial call
		this.load();

		this.unlisten = history.listen((location, action) => {
			this.route(location);
		})
	}
	render() {
		const card = this.state.cardid === 0?null:this.state.lookup[this.state.cardid];
		return (
			<Stage>
				<StageContent>
					<TransitionGroup>
						<Fade timeout={500} key={this.state.screen} reverse={this.state.reverse}>
							{(() => {
								switch(this.state.screen) {
									case 1:
										return <div>Dummy</div>
									case 2:
										return <HomePage
											cards={this.state.cards}
											key={this.state.screen}
											onStart={
												() => history.push({pathname:"/select"})
											}
											onSelect={
												(cardid) => history.push({pathname:cardid})
											}/>
									case 3:
										return <SelectCard
											key={this.state.screen}
                      onBack={
                        () => {
													history.push({pathname:"/"});
                        }
                      }
											onSelect={
												(number) => {
													this.setState({
														data: Object.assign({}, this.state.data, {Number: number})
													});
													history.push({pathname:"/text"});
												}}/>;
									case 4:
										return <EnterText
											key={this.state.screen}
											name={this.state.data.Name}
											text={this.state.data.Text}
                      submitted={this.state.submitted}
											onBack={
												() => history.push({pathname:"/select"})
											}
											onChange={
												(field, value) => {
													const change = {};
													change[field] = value;
													this.setState({
														data: Object.assign({}, this.state.data, change)
													})
												}
											}
											onSubmit={() => {
											  if(this.state.data.Name === '' || this.state.data.Text === '') {
											    this.setState({
                            submitted: true
                          })
                        } else {
                          this.sendAndReload()
                        }
											}}/>;
									case 5:
										return <Details key={this.state.screen} card={card} onBack={() => history.push({pathname:'/'})}/>
									default:
										return <div>Ende!</div>
								}
							})()}
						</Fade>
					</TransitionGroup>
				</StageContent>
			</Stage>
		);
	}
}
