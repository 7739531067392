import React, {Component, Fragment} from 'react';
import {ThemeProvider} from "styled-components";
import theme from "./theme";
import {Normalize} from "styled-normalize";
import {GlobalStyle} from "./global";
import Main from "./Main";

class App extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Fragment>
                    <Normalize/>
                    <GlobalStyle/>
                    <div>
                        <Main api={this.props.api}/>
                    </div>
                </Fragment>
            </ThemeProvider>
        );
    }
}

export default App;
