import {createGlobalStyle} from "styled-components";
import theme from "./theme";

require("./fonts.css");

export const GlobalStyle = createGlobalStyle`
  *, :before, :after {
    box-sizing: border-box;
  }
  body {
    overflow-x:hidden;
    width:100%;
  }
  body, p, td {
    font: ${theme.fonts.standard};
  }
  a {
    color:inherit;
    text-decoration:inherit;
  }
  p, h1, h2, h3 {
    margin:0;
  }
  p {
    margin-bottom:1em;
  }
[data-preview] {
  position: relative;
  transform: translate3d(0, 0, 0); /* <-- THIS LINE MAKES THE TRICK! */
  outline: 1px solid #eaeaea;
}

#root {
    width:100%;
}
`;
