import React from "react";
import styled from 'styled-components';

export const ArrowStyled = styled.span`
    display:inline-block;  
    margin-left: ${props => props.direction === 'left'?'0':'15px'};
    margin-right: ${props => props.direction === 'right'?'0':'15px'};
`;

const Arrow = ({direction, onClick, color}) => <ArrowStyled onClick={onClick} direction={direction}>
    {direction==='left'?<svg width="22" height="18" xmlns="http://www.w3.org/2000/svg">
        <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd">
            <path d="M22 9H2"/>
            <path strokeLinecap="square" d="M9 16L2 9l7-7"/>
        </g>
    </svg>:<svg width="23" height="18" xmlns="http://www.w3.org/2000/svg">
            <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd">
                <path d="M.583 9.146H21"/>
                <path strokeLinecap="square" d="M13.854 16.292L21 9.146 13.854 2"/>
            </g>
        </svg>}
    </ArrowStyled>;

Arrow.defaultProps = {
    color: '#000000'
};

export default Arrow;
