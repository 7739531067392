import React from 'react';
import styled from 'styled-components';
import media from '../../utils/media';
import Button from "../../layout/Button/Button";
import WichtelOhneNameSchreiben from "../../Wichtel/WichtelOhneNameSchreiben/WichtelOhneNameSchreiben";
import Arrow from "../../layout/Arrow/Arrow";
import CardBack, {Wrapper} from "../../figures/CardBack/CardBack";
import Container from "../../layout/Container/Container";

const Card = styled.div`
    position:relative;
    z-index:2;
    overflow:hidden;
    margin-right:-20px;
    
    ${Wrapper} {
      width:160%;
    }

    ${media.greaterThan('medium')`
        width:80%;
        position:absolute;
        right:40px;
        top:120px;
        
        ${Wrapper} {
          width:100%;
        }
    `}
    ${media.greaterThan('desktop')`
        width:60%;

       @media only screen and (max-height: 600px) {
        width:50%;
       }
    `}
`;

const Wichtel = styled.div`
    display:none;
    ${media.greaterThan('medium')`
        display:block;
        width: 25%;
        height: 40%;        
        position:absolute;
        bottom:0;
        left:40px;
    `}
    ${media.greaterThan('desktop')`
        width:15%;
        height:50%;
    `}
`;

const Buttons = styled.div`
  padding:10px 0;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  
  button {
    margin-bottom:10px;
  }
  
  ${media.greaterThan('medium')`
    position:absolute;
    width: 60%;
    bottom:60px;
    right:40px;
    z-index:10;
    flex-direction: row;
  `}
`;

const Intro = styled.p`
  padding:0px 20px 0;
  ${media.greaterThan('medium')`
      padding-top:20px;
  `}
`;

const EnterText = ({name, text, submitted, onBack, onSubmit, onChange}) => {
  return (
    <Container>
      <Intro>
        Schreibe hier Deinen Wichtelwunsch auf!
      </Intro>
      <Card>
        <CardBack edit={true} onChange={onChange} name={name} text={text} error={submitted && (!name || !text)}/>
      </Card>
      <Wichtel>
        <WichtelOhneNameSchreiben/>
      </Wichtel>
      <Buttons>
        <Button colour={true} onClick={onBack}><Arrow color={'#fff'} direction={'left'}/>
          zurück zur Motivauswahl
        </Button>
        <Button onClick={onSubmit}>
          weiter <Arrow color={'#fff'} direction={'right'}/>
        </Button>
      </Buttons>
    </Container>
  );
};

export default EnterText;
