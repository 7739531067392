import React from 'react';
import styled from 'styled-components';
import media from '../../utils/media';

const Floor = styled.footer`
  width:100%;
  z-index:1;
  background-color: ${props => props.outdoor ? '#4A4A4A' : '#E6CFB3'};

  ${media.greaterThan('medium')`
    position:absolute;
    bottom:0;
    left:0;
  `}

`;

const FloorContent = styled.div`
  width:100%;
  //max-width:1200px;
  padding:20px 20px;
  text-align:right;
`;

const Logo = styled.a`
  text-transform:uppercase;
  color: ${props => props.outdoor ? '#FFFFFF' : '#4A4A4A'};
  
  strong {
    font-weight:700;
  }
`;

const Footer = ({fixed, outdoor}) => <Floor outdoor={outdoor}>
  <FloorContent>
    <Logo outdoor={outdoor} target="_blank" href="https://www.adiwidjaja.com/">
      Adiwidjaja<strong>Teamworks</strong>
    </Logo>
  </FloorContent>
</Floor>;


export default Footer;
