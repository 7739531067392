import React from 'react';
import styled from "styled-components";
import media from '../../utils/media';

import tuerschild from './atw_wichtelwuensche_schild.svg';
import lichterkette from './lichterkette_loop.svg';
import Lottie from 'react-lottie';

import animationData from './WichtelAlvinAnimation.json';

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    position: relative;
`;

const Tuerschild = styled.img`
    display: block;
    position: absolute;
    top: 250px;
    left: 50%;
    margin-left: 200px;
    height: 80px;
    width: 171px;
    cursor:pointer;

    ${media.lessThan('600px')`
        display:none;
    `}


`;

const Wrapperhouse = styled.div`
    width: 100%;
`;

const WrapperLichterketten = styled.div `
    height:80px;
    background-image:url(${lichterkette});
    background-size: 500px 65px;
    background-repeat: repeat-x;
    background-position:calc(50% + 250px) center;
`;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const WichtelAlvinTuer = () => <Wrapper>
    <WrapperLichterketten>
    </WrapperLichterketten>
    <Wrapperhouse>
        <Lottie options={defaultOptions}
                height={410}
                width={300}/>
        <Tuerschild src={tuerschild}/>
    </Wrapperhouse>
</Wrapper>;

export default WichtelAlvinTuer;
