import React from 'react';
import styled from "styled-components";

import back from './07_atw_weihnachtskarte_rueckseite.svg';
import nostamp from './08_atw_weihnachtskarte_rueckseite_ohne_stempel.svg';
import media from "../../utils/media";

const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;

const Card = styled.img`
    width: 100%;
    display:block;
    border-radius: 6%;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
`;

const CardContent = styled.div`
    position:absolute;
    top:8%;
    left:4%;
    width:50%;
`;

const CardName = styled.p`
    font-family: Cookie, sans-serif;
`;

const CardText = styled.p`
    white-space: pre-line;
    font-family: Cookie, sans-serif;
`;

const CardForm = styled.form`
    width:100%;
`;

const FormRow = styled.div`
    padding:20px;
    display:flex;
    font-family:Cookie, sans-serif;
`;

const Label = styled.label`
    white-space:nowrap;
    padding-right:10px;
    color: #33629C;
`;

const FormInput = styled.input.attrs({type: 'text'})`
    font-family:Cookie, sans-serif;
    width:100%;
    border:${props => props.error?'1px solid red':'none'};
    color: #33629C;
    
    &:focus {
      outline:none;
      background-color:#eee;
    }
`;

const FormTextarea = styled.textarea`
    font-family:Cookie, sans-serif;
    width:100%;
    border:none;
    height:200px;
    resize: none;
    border:${props => props.error?'1px solid red':'none'};
    ${media.lessThan('590px')`
        height: 400px;
    `}
    ${media.lessThan('494px')`
        height: 250px;
    `}
    
    &:focus {
      outline:none;
      background-color:#eee;
    }
`;


const CardBack = ({ name, text, edit, onChange, stamp, error}) => <Wrapper>
    <Card src={stamp?back:nostamp}/>
    {edit?<CardContent>
            <CardForm>
                <FormRow>
                    <Label>Wichtelwunsch von</Label><FormInput
                      value={name}
                      error={error}
                      placeholder={'Dein Name'}
                      onChange={
                        (event) => onChange('Name', event.target.value)
                    }/>
                </FormRow>
                <FormRow>
                    <FormTextarea
                      value={text}
                      error={error}
                      placeholder={'Dein Text'}
                      onChange={
                        (event) => onChange('Text', event.target.value)
                    }/>
                </FormRow>
            </CardForm>
        </CardContent>
        :<CardContent>
            <CardName>Wichtelwunsch von {name}</CardName>
        <CardText>{text}</CardText>
    </CardContent>}
</Wrapper>;

CardBack.defaultProps = {
    name: '',
    text: '',
    edit: false,
};

export default CardBack;
export { Wrapper }
