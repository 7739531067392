import React from 'react';
import styled from "styled-components";

/*import wichtelon from './karte_teilen_wichtel.svg';*/
import Lottie from 'react-lottie';
import animationData from './WichtelOhneNameTeilen.json';
/*import XmasCard from "../../figures/XmasCard/XmasCard";*/

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

/*const Wichtel = styled.img`
    height: 450px;
`;*/

const WrapperCard = styled.div`
    position: absolute;
    top:40%;
    left:10%;
    width:80%;
`;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const WichtelOhneNameTeilen = ({children}) => <Wrapper>
    <Lottie options={defaultOptions}/>
    <WrapperCard>
        {children}
    </WrapperCard>
</Wrapper>;

export default WichtelOhneNameTeilen;
