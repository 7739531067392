import React from 'react';
import styled from "styled-components";

import Lottie from 'react-lottie';
import animationData from '../WichtelAlvinMotive/WichtelAlvinTeilen.json'

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding:20px;
`;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        scaleMode: 'fit',
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const WichtelAlvinMotive = () => <Wrapper>
    <Lottie options={defaultOptions}/>
</Wrapper>;

export default WichtelAlvinMotive;
