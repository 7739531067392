import React, {Fragment} from 'react';
import styled from "styled-components";

import card1 from './01_atw_weihnachtskarte.svg';
import card2 from './02_atw_weihnachtskarte.svg';
import card3 from './03_atw_weihnachtskarte.svg';
import card4 from './04_atw_weihnachtskarte.svg';
import card5 from './05_atw_weihnachtskarte.svg';
import card6 from './06_atw_weihnachtskarte.svg';
import card7 from './07_atw_weihnachtskarte_rueckseite.svg';
import tag from './smalltag.svg';
import media from "../../utils/media";

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 8px;
        height: 20px;
        background: #B27A2A;
        margin-right: 40px;
        margin-top: -9px;
        z-index: 10;
        cursor:pointer;
        display:none;
    ${media.greaterThan("medium")`
        display:${(props) => props.hasName?'block':'none'};
        height: 25px;
        width: 13px;
    `};
    ${media.greaterThan("desktop")`
        width: 13px;
        height: 30px;
        margin-right: 15px;
    `};
    }    
`;

const Nametag = styled.img`
    position: absolute;
    display: ${props => props.show ? "none" : "auto"};
    top: 0;
    right: 0;
    height: 40px;
    margin-right: 15px;
    cursor:pointer;
    display:none;
    ${media.greaterThan("medium")`
        display:block;
        height: 50px;
        margin-right: 12px;
    `};
    ${media.greaterThan("desktop")`
        height: 60px;
        margin-right: 10px;
    `};
`;

const Nametagtext = styled.div`
    position: absolute;
    display: ${props => props.owner ? "none" : "auto"};
    top: 0;
    right: 0;
    min-width: 100px;
    height: 39px;
    background-color: #C5B08B;
    margin-right: 23px;
    padding: 15px;
    padding-top: 5px;
    padding-right: 15px;
    color: #fff;
    font-family: Cookie, sans-serif;
    font-size: 15px;
    cursor:pointer;
    display:none;
    ${media.greaterThan("medium")`
        display:block;
        height: 50px;
        font-size: 22px;
        padding-top: 11.5px;
    `};    
    ${media.greaterThan("desktop")`
        height: 60px;
        margin-right: 23px;
        font-size: 25px;
        padding-top: 17px;
    `};
`;

const MobileTag = styled.p`
    display:block;
    color: #fff;
    font-size: 14px;
    margin:0;
    ${media.greaterThan("medium")`
        display:none;
   `}
`;

const Card = styled.img`
    width: 100%;
    display:block;
    border-radius: 6%;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    cursor:pointer;
`;

const cards = [
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7
];

// console.log(cards);

const XmasCard = ({owner, number, onClick, visibility}) => <Wrapper hasName={!!owner}>
  <Card src={cards[number]} onClick={onClick} selected={visibility}/>
  {owner ? <Fragment>
    <Nametag src={tag} onClick={onClick} show={!!owner}/>
    <Nametagtext onClick={onClick} selected={visibility}>{owner}</Nametagtext>
  </Fragment> : null}
  <MobileTag>{owner}</MobileTag>
</Wrapper>;

export default XmasCard;
export {Wrapper}
