import React from 'react';
import styled from "styled-components";
import bg from "../../utils/bg.jpg";
import Footer from "../../layout/Footer/Footer";
import media from '../../utils/media';

const ContainerWrapper = styled.div`
    width:100%;
    min-height:100%;

    background-image:url("${bg}");
    background-size:cover;

  ${media.greaterThan('medium')`
    position:fixed;
    top:0;
    left:0;
    height:100%;
  `}

    
    
`;
const Content = styled.div`
    width:100%;
    max-width:1400px;
    margin:0 auto;
    height:calc(100vh - 20px);
    z-index:2;
    padding:20px;

  ${media.greaterThan('medium')`
    position:fixed;
    bottom:20px;
    left:50%;
    transform:translateX(-50%);
  `}


`;

const Container = ({ children }) => <ContainerWrapper>
  <Content>
    {children}
  </Content>
  <Footer/>
</ContainerWrapper>

export default Container;
