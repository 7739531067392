import React from 'react';
import styled from 'styled-components';
import media from '../../utils/media';
import XmasCard, { Wrapper } from "../../figures/XmasCard/XmasCard";
import WichtelAlvinMotive from "../../Wichtel/WichtelAlvinMotive/WichtelAlvinMotive";
import Container from "../../layout/Container/Container";
import Button from "../../layout/Button/Button";
import Arrow from "../../layout/Arrow/Arrow";


const CardSelection = styled.div`
    display:flex;
    flex-wrap:wrap;
    width:100%;
    min-height:50%;

    ${Wrapper} {
        width:50%;
        padding:10px;
        
        // ${media.greaterThan('medium')`
        //   width: calc(100%/3);
        // `}
    }
    
    ${media.greaterThan('medium')`
        width:80%;
        position:absolute;
        top:80px;
        right:40px;
    `}
    
    ${media.greaterThan('desktop')`
        width:60%;
        top:40px;
        right:40px;
        
        @media only screen and (max-height: 600px) {
          // width:50%;
          
          ${Wrapper} {
            width: calc(100% / 3);
          }
        }
    `}
`;

const Wichtel = styled.div`
    height:30%;
    transform:scale(0.5);
    transform-origin:center top;
    display:none;

    ${media.greaterThan('medium')`
      display:block;
        width:40%;
        height:40%;
        position:absolute;
        bottom:0;
        left:40px;
        transform:none;
    `}
    ${media.greaterThan('desktop')`
        width:30%;
        height:50%;
        position:absolute;
        bottom:0;
        left:40px;
        transform:none;
    `}
`;

const Buttons = styled.div`
  padding:20px 0 10px;
  display:flex;
  flex-direction: column;
  justify-content:flex-end;
  
  button {
    margin-bottom:10px;
  }
  
  ${media.greaterThan('medium')`
    position:absolute;
    width: 40%;
    bottom:60px;
    right:40px;
    flex-direction: row;
  `}
`;

const Intro = styled.p`
  padding:20px 20px 0;
`;

const SelectCard = ({onSelect, onBack}) => {
  return (
    <Container>
      <Intro>
        Such Dir eine Postkarte aus!
      </Intro>
      <CardSelection>
        <XmasCard number={0} onClick={() => onSelect(0)}/>
        <XmasCard number={1} onClick={() => onSelect(1)}/>
        <XmasCard number={2} onClick={() => onSelect(2)}/>
        <XmasCard number={3} onClick={() => onSelect(3)}/>
        <XmasCard number={4} onClick={() => onSelect(4)}/>
        <XmasCard number={5} onClick={() => onSelect(5)}/>
      </CardSelection>
      <Wichtel>
        <WichtelAlvinMotive/>
      </Wichtel>
      <Buttons>
        <Button colour={true} onClick={onBack}><Arrow color={'#fff'} direction={'left'}/>
          zurück zur Übersicht
        </Button>
      </Buttons>
    </Container>
  );
};

export default SelectCard;
