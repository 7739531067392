import React from 'react';
import styled from "styled-components";

import Lottie from 'react-lottie';

import animationData from './WichtelOhneNamenSchreiben.json';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        scaleMode: 'fit',
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const WichtelOhneNameSchreiben = () => <Wrapper>
    <Lottie options={defaultOptions}/>
</Wrapper>;

export default WichtelOhneNameSchreiben;
