import transition from "styled-transition-group"

//@component
export default transition.div`
  width: 100%;

  &:enter {
    opacity: 0.01;
    // transform: translate3d(${props => props.reverse?'-50%':'50%'},0,0);
  }
  &:enter-active {
    opacity: 1;
    // transform: translate3d(0,0,0);
    transition: opacity ${props => props.timeout}ms ease-in, transform ${props => props.timeout}ms ease-in;
  }
  &:exit {
    opacity: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    // transform: translate3d(0,0,0);
  }
  &:exit-active {
    opacity: 0.01;
    // transform: translate3d(${props => props.reverse?'50%':'-50%'},0,0);
    transition: opacity ${props => props.timeout}ms ease-in, transform ${props => props.timeout}ms ease-in;
  }
`
