const theme = {
    colors: {
        white: '#ffffff',
        whiteTrans: 'rgba(255,255,255,0.8)',
        lightGrey: '#eee',
        background: '#ffffff',
        inactive: '#A4A4A4',
        text: '#001C00',
        yellow: '#F8E11C',
        error: 'red'
    },
    fonts: {
        specialHead: '900 70px/120px "Avenir", sans-serif',
        headline: '300 50px/70px "Avenir", sans-serif',
        headline2: '300 30px/40px "Avenir", sans-serif',
        standard: '300 22px/30px Avenir, sans-serif',
        bold: '700 16px/20px Avenir, sans-serif',
    }
};

export default theme;
