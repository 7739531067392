import React from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
    height: 50px;
    min-width: 100px;
    outline: 0;
    border: 0;
    background-color: ${props => props.selected ? "#D5594D" : "#48AF5F"};
    color: #fff;
    font-family: Cookie, sans-serif;
    font-size: 28px;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    cursor: pointer;
    white-space: nowrap;
`;

const Button = ({ children, onClick, selected, colour }) => <ButtonStyled onClick={onClick} selected={colour}>
    { children }
</ButtonStyled>

export default Button;
