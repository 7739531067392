import React from 'react';
import styled from 'styled-components';
import media from '../../utils/media';
import XmasCard from "../../figures/XmasCard/XmasCard";
import WichtelOhneNameTeilen from "../../Wichtel/WichtelOhneNameTeilen/WichtelOhneNameTeilen";
import CardBack, {Wrapper} from "../../figures/CardBack/CardBack";
import Button from "../../layout/Button/Button";
import Arrow from "../../layout/Arrow/Arrow";
import Container from "../../layout/Container/Container";

import icon_facebook from './icon_facebook.svg';
import icon_mail from './icon_mail.svg';
import icon_twitter from './icon_twitter.svg';
import icon_whatsapp from './icon_whatsapp.svg';

const Card = styled.div`
    position:relative;
    z-index:2;
    overflow:hidden;
    margin-right:-20px;
    
    ${Wrapper} {
      width:160%;
    }
    
    ${media.greaterThan('medium')`
        width:calc(100% - 80px);
        position:absolute;
        right:40px;
        top:80px;
        
        ${Wrapper} {
          width:100%;
        }
    `}
    ${media.greaterThan('desktop')`
        width:60%;
        top:20px;
    `}
`;

const Wichtel = styled.div`
  display:none;
    position: absolute;
    left:40px;
    bottom:0;
    width: 30%;
    z-index:3;

    ${media.greaterThan('medium')`
      display:block;
        width:210px;
        height:420px;
    `}
    ${media.greaterThan('desktop')`
        position:absolute;
        bottom:0;
        left:40px;
    `}
`;

const ButtonWrapper = styled.div`
    width: 100%;
    margin-top:20px;
    
  ${media.greaterThan('medium')`
    position:absolute;
    width:50%;
    bottom:200px;
    right:40px;
    z-index:10;
  `}

  ${media.greaterThan('desktop')`
    width:30%;
    bottom:80px;
    right:330px;
    padding-bottom:10px;
  `}

`;

const Share = styled.div`
  display:flex;
  width:100%;
  justify-content: space-between;
  align-items: center;
  margin-top:20px;

  ${media.greaterThan('medium')`
    position:absolute;
    width:300px;
    bottom:80px;
    right:40px;
  `}

  ${media.greaterThan('desktop')`
    width:30%;
  `}

`;

const Link = styled.a`
  padding:0 15px;
  width:25%;
`;

const WhatsAppLink = styled(Link)`
  ${media.greaterThan('medium')`
    display:none;
  `}
`;

const Icon = styled.img`
  width:100%;
  max-height:80%;
`;

const Details = ({card, onBack}) => {
  const link = window.location.href;
  return (
    <Container>
      <Card>
        {card ? <CardBack name={card.Name} text={card.Text} stamp/> : null}
      </Card>
      <ButtonWrapper>
        <Button onClick={onBack}><Arrow color={'#fff'} direction={'left'}/>Zurück zur Übersicht</Button>
      </ButtonWrapper>
      <Share>
        <div>Jetzt teilen:</div>
        <Link href={`mailto:?subject=Mein Wichtelwunsch&body=Mein%20Wichtelwunsch:%20${link}`}>
          <Icon src={icon_mail}/>
        </Link>
        <Link href={`https://www.facebook.com/sharer/sharer.php?u=${link}`} target="_blank">
          <Icon src={icon_facebook}/>
        </Link>
        <Link href={`https://twitter.com/intent/tweet?url=${link}&text=Mein%20Wichtelwunsch`} target="_blank">
          <Icon src={icon_twitter}/>
        </Link>
        <WhatsAppLink href={`whatsapp://send?text=Schau Dir meinen Wichtelwunsch an%3A%20${link}"`}>
          <Icon src={icon_whatsapp}/>
        </WhatsAppLink>
      </Share>
      <Wichtel>
        <WichtelOhneNameTeilen>
          {card ? <XmasCard number={card.Number}/> : null}
        </WichtelOhneNameTeilen>
      </Wichtel>
    </Container>
  );
};

export default Details;
