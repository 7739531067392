import React, { Component } from 'react';
import styled from 'styled-components';
import WichtelAlvinTuer from "../../Wichtel/WichtelAlvinTuer/WichtelAlvinTuer";
import CardList from "../../layout/CardList/CardList";
import XmasCard from "../../figures/XmasCard/XmasCard";
import media from '../../utils/media';

import bg from "../../utils/bg.jpg";
import Footer from "../../layout/Footer/Footer";
import Button from "../../layout/Button/Button";
import Arrow from "../../layout/Arrow/Arrow";

import sterne from "./hintergrund_sterne.svg";

const HomePageContainer = styled.div`
    display:flex;
    flex-direction:column;
    position:relative;
`;

const CardContainer = styled.div`
    width:100%;
    min-height:800px;
    background-image:url("${sterne}");
    
`;

const EntranceContainer = styled.div`
    width:100%;
    background-image:url("${bg}");
    background-size:cover;
`;

const Entrance = styled.div`
    cursor:pointer;
    position:relative;
    z-index:10;
    
    ${media.greaterThan('medium')`
      margin-bottom:75px;
    `}
`;

const Intro = styled.div`
  padding:20px;
  
  ${media.greaterThan('desktop')`
    position:absolute;
    width:30%;
    bottom:80px;
    left:20px;
  `}

`;

class HomePage extends Component {
  componentDidMount() {
    console.log(document.body.scrollHeight);
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }, 500);

  }
  render() {
    const {cards, onStart, onSelect} = this.props;
    return (
      <HomePageContainer>
        <CardContainer>
          <CardList>
            {cards.map((card, index) =>
              <XmasCard
                key={index}
                number={card.Number}
                owner={card.Name}
                onClick={() => onSelect(card.ID)}/>
            )}
          </CardList>
        </CardContainer>
        <EntranceContainer>
          <Entrance onClick={onStart}>
            <Intro>
              <p>
                Wir haben im Büro Untermieter: Ein Wichtelbüro ist eingezogen. Auch online kann
                man jetzt Wünsche aufschreiben und auch mit anderen teilen.</p>
              <Button>Start <Arrow color={'white'}/></Button>
            </Intro>
            <WichtelAlvinTuer/>
          </Entrance>
          <Footer outdoor/>
        </EntranceContainer>
      </HomePageContainer>
    );
  };
}

export default HomePage;
