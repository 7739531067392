import React from 'react';
import styled from "styled-components";
import media from "../../utils/media";
/*import XmasCard from "../../figures/XmasCard/XmasCard";*/

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
`;

const CardListItem = styled.div`
    flex: 0 0 auto;
    width: 50%;
    padding: 10px;
    
    &:nth-child(4n+0) {
      transform:translateY(-15px);
    }
    &:nth-child(3n+1) {
      transform:translateY(15px);
    }
    
    ${media.greaterThan("medium")`
        width: calc(100% / 3);
    `};
    
    ${media.greaterThan("desktop")`
        width: 25%;
        padding: 20px;
    `};
    ${media.greaterThan("1200px")`
        width: 20%;
    `};
    ${media.greaterThan("1600px")`
        width: calc(100% / 6);
    `};
`;

const CardList = ({ children }) => <Wrapper>
    {children.map( (child, index) => <CardListItem key={index}>
        {child}
    </CardListItem> )}
</Wrapper>;

CardList.defaultProps = {
    children: []
};

export default CardList;
