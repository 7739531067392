import "@babel/polyfill";
import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App';


const rootElement = document.getElementById('root');
let api_url = rootElement.getAttribute('data-api');
if(api_url === '$ApiUrl')
    api_url = 'http://xmas18.test/api/v1/';

if (rootElement.hasChildNodes()) {
    hydrate(<App api={api_url}/>, rootElement);
} else {
    render(<App api={api_url} />, rootElement);
}
